import React from "react"
import { Link } from "gatsby"
import styles from "./navbar.module.css"


export default function NavBar() {
  return (
    <nav className={styles.nav}>
      <h3>
        <Link id={styles.siteTitle} to="/">
          Patrick O'Hern
        </Link>
      </h3>
      <Link
        to="/"
        className={styles.link}
        activeStyle={{
          fontWeight: "bold",
          color: "white",
          textDecoration: "underline",
        }}
      >
        Home
      </Link>
      {/* <Link
        to="/contact"
        className={styles.link}
        activeStyle={{
          fontWeight: "bold",
          color: "white",
          textDecoration: "underline",
        }}
      >
        Contact Me
      </Link> */}
      {/* <Link
        to="/aboutME"
        className={styles.link}
        activeStyle={{
          fontWeight: "bold",
          color: "white",
          textDecoration: "underline",
        }}
      >
        About Me
      </Link> */}
      <Link
        to="/projects"
        className={styles.link}
        activeStyle={{
          fontWeight: "bold",
          color: "white",
          textDecoration: "underline",
        }}
      >
        My Projects
      </Link>
      <a
        href="https://github.com/pohern"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <p>GitHub</p> */}
        <img
          src="https://www.sferalabs.cc/wp-content/uploads/github-logo-white.png"
          // src="https://cdn.jsdelivr.net/npm/simple-icons@3.0.1/icons/github.svg"
          style={{ width: "80px", height: "50px" }}
          alt="GitHub"
        ></img>
      </a>
      <a
        href="https://www.linkedin.com/in/patrick-ohern//"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: "1rem" }}
      >
        {/* <p>Let's Connect On LinkedIn</p> */}
        <img
          src="https://iconsplace.com/wp-content/uploads/_icons/ffffff/256/png/linkedin-icon-18-256.png"
          // src="https://cdn.jsdelivr.net/npm/simple-icons@3.0.1/icons/linkedin.svg"
          style={{ width: "50px", height: "50px" }}
          alt="LinkedIn"
        ></img>
      </a>
      <a
        href="tel:5053015464"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: "1rem" }}
      >
        <img
          src="https://www.pikpng.com/pngl/b/193-1930785_call-icon-white-png-portable-network-graphics-clipart.png"
          style={{ width: "50px" }}
          alt="Phone"
        ></img>
      </a>
    </nav>
  )
}
