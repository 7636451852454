import React from "react"
import styles from './footer.module.css'



export default function Footer() {
  return (
    <footer className={styles.footer}>
      <p style={{ textAlign: "right" }}>
        <small>&copy; {new Date().getFullYear()} Patrick O'Hern</small>
      </p>
    </footer>
  )
}


      